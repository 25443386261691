<template>
  <div class="signin">
    <div style="align-items: center; justify-content: center;">
        <div>
          <div class="text-center">
            <!-- <h4 class="margin-top-10"><span class="fa fa-lock"></span> Reset Password</h4> -->
          </div>
           <div class="pad-bottom-30 text-center">
                <img src="/logo.png" @click="$router.push('/')" class="img-fluid c-pointer" style="height:7vh;" alt="">
              </div>
          <div class="form-group">
              <label for="">Mobile Number<span class="req">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">+91</span>
                </div>
                <input type="number" @keyup.enter="signup()" class="form-control" v-model="payload.mobile" placeholder="Mobile Number">
              </div>
              <span v-if="error && error.mobile" class="error">{{error.mobile}}</span>
          </div>
          <div class="text-left pad-bottom-10">
            <button class="btn btn-success btn-sm" @click="requestOTP()">Request OTP</button>
          </div>
          <div class="form-group">
              <label for="">Mobile OTP<span class="req">*</span></label>
              <input type="number" maxlength="6" @keyup.enter="signup()" class="form-control" v-model="payload.otp" placeholder="OTP received to mobile">
              <span v-if="error && error.otp" class="error">{{error.otp}}</span>
          </div>
          <div class="form-group">
              <label for="">New Password<span class="req">*</span></label>
              <input type="password" @keyup.enter="signup()" class="form-control" v-model="payload.password" placeholder="New Password">
              <span v-if="error && error.password" class="error">{{error.password}}</span>
          </div>
          <!-- <div class="text-center">
              <button class="btn btn-success" @click="signup()">Reset Password</button>
          </div> -->
          <div class="text-center">
              <button class="btn btn-success btn-eb-full black margin-bottom-20" @click="signup()">Reset Password</button>
              <button class="btn btn-outline-dark btn-eb-full" @click="visitLogin()">Back to Login</button>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      payload: {
          otp: null,
          mobile: null,
          password: null,
      },
      error: null,
    }
  },
  methods: {
    visitLogin() {
      this.$emit('visitLogin')
    },
    requestOTP() {
      const that = this;
      that.error = null;
      if (that.payload.mobile === null || that.payload.mobile === "") {
        this.error = {};
        this.error.mobile = "Registered mobile no is required to send OTP";
        return
      }
      if (that.payload.mobile && (that.payload.mobile.length != 10 || parseInt(that.payload.mobile[0])<5)) {
        this.error = {};
        this.error.mobile = "Enter 10 digit valid mobile number";
        return
      }
      that.$cs.profile.update({
        resource: `${process.env.VUE_APP_API_URL}/otp/`,
        data: {
          mobile: that.payload.mobile
        }
      })
      .then(res=>{
        // window.location.href = "/signin/";
      },err=>{
        if(err.response.status === 400) {
          this.error = err.response.data;
        }
      })
    },
    signup() {
      const that = this;
      if(that.validateSignup()) {
        that.$cs.profile.create({
        resource: `${process.env.VUE_APP_API_URL}/forgot-password/`,
        data: that.payload
        })
        .then(res=>{
          window.location.href = "/signin/";
        },err=>{
          if(err.response.status === 400) {
            this.error = err.response.data;
          }
        })
      }
    },
    validateSignup() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.otp === null || that.payload.otp === "") {
        this.error.otp = "OTP is required to reset password";
        proceed = false;
      }
      if (that.payload.mobile === null || that.payload.mobile === "") {
        this.error.mobile = "Mobile field should not be null";
        proceed = false;
      }
      if (that.payload.password === null || that.payload.password === "") {
        this.error.password = "Password field should not be null";
        proceed = false;
      }
      return proceed;
    }
  }
}
</script>
<style lang="stylus" scoped>
.menu-list
  list-style none
  margin 0px
  padding 0px
  width 100%
  display flow-root
  li
    padding 10px
    width 50%
    float left
    font-weight 700
    letter-spacing 0.5px
    font-size 1.3em
    text-align center
    cursor pointer
    &.active
      border-bottom 3px solid #0041c4
.signin
    padding 10vh
    padding-top 0px
    width 100%
    .form-block
      display block
      border 1px solid #ddd
      padding 15px
@media (max-width: 767px)
  .signin
    padding 0vh
    .form-block
      min-height 90vh
</style>
